import React,{useState,useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar';
import Home from "./Components/Home";
import About from "./Components/About";
import Events from "./Components/Events";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import PopupDialogFirst from "./Components/popupfirst";
import "./App.css";


const App = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  useEffect(() => {
    setOpenPopup(true);
  }, []);  

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  return (
    <Router>
      <PopupDialogFirst open={openPopup} handleClose={handleClosePopup} />
      <div className="App">
        <Navbar />
        <section id="home">
          <Home />
        </section>
        <section id="about">
          <About />
        </section>
        <section id="events">
          <Events />
        </section>
        {/* <section id="work">
          <Work />
        </section> */}
        <section id="testimonial">
          <Testimonial />
        </section>
        <section id="contact">
          <Contact />
        </section>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
