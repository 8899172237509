import React, { useState } from "react";
// import BannerBackground from "../Assets/home-banner-background.png";
// import BannerImage from "../Assets/home-banner-image.png";
import { FiArrowRight } from "react-icons/fi";
import Carouselscreen from "./Carousel";

// No need to import an image from URL, use it directly in JSX
// import ExampleCarouselImage from 'https://tse1.mm.bing.net/th?id=OIP.dQ7YdITVWFtImq5-GUaEKQHaEK&pid=Api&rs=1&c=1&qlt=95&h=180';

const Home = () => {

  return (
    <div className="home-container">
      <Carouselscreen/>

      {/* <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="Banner Background" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            Your Favourite Food Delivered Hot & Fresh
          </h1>
          <p className="primary-text">
            Healthy switcher chefs do all the prep work, like peeling, chopping
            & marinating, so you can cook fresh food.
          </p>
          <button className="secondary-button">
            Order Now <FiArrowRight />
          </button>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="Banner" />
         
        </div>
      </div> */}

    </div>
  );
};

export default Home;
