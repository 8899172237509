import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import qrcode from "../Assets/NextGen_Tennis_QRCode.jpg";
import "./popup.css"

const PopupDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} className="popup-dialog">
      <DialogContent className="dialog-content">
        <div className="close-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        <img src={qrcode} alt="QR Code" className="qr-code-image" />
      </DialogContent>
    </Dialog>
  );
};

export default PopupDialog;
