import React from 'react'

const FallComponent = () => {
  return (
    <div class="about-section-container mt-5 w-100">
      <p className="primary-subheading lh-1 ">2024 FALL SESSION CLINICS</p>
      <p className="fs-5 text-break w-100">
        Actual clinic schedules are subject to change based on player availability and ongoing sign-ups. We appreciate your flexibility as we work to accommodate the needs of all participants and ensure the best possible training experience for everyone.
        </p>
        <h1 className="primary-heading  ">
        ( SEP 9, 2024 - Oct 31, 2024 ) - 8 WEEKS
            </h1>
        
            <p> MT LAUREL, NJ</p>
  <div class="card-deck">
    <div class="card w-100 card-red mb-4">
      <div class="card-body">
        <h5 class="card-title">Red Ball</h5>
        <p class="card-text"><strong>Duration:</strong> 8 Weeks</p>
        <p class="card-text"><strong>Location:</strong>  Mt Laurel, NJ</p>
        <p class="card-text"><strong>Days:</strong> Mon & Wed</p>
        <p class="card-text"><strong>Time:</strong> 4:30 - 5:30</p>
        <p class="card-text"><strong>Ages:</strong> 4-8</p>
        <p class="card-text"><strong>Availability:</strong> 12 spots/week</p>
        <a target="_blank" href="https://docs.google.com/forms/d/13T-RAu0qqPHf2RqYjXiiznAZNjuVXp8TsGo0gwiTEhE/edit" class="btn btn-primary btn-sm primaryjoin-button w-100 mt-2 ">Register</a>
      </div>
    </div>
    <div class="card w-100 card-orange mb-4">
      <div class="card-body">
        <h5 class="card-title">Orange Ball</h5>
        <p class="card-text"><strong>Duration:</strong> 8 Weeks</p>
        <p class="card-text"><strong>Location:</strong>  Mt Laurel, NJ</p>
        <p class="card-text"><strong>Days:</strong> Mon & Wed</p>
        <p class="card-text"><strong>Time:</strong> 5:30 - 6:30</p>
        <p class="card-text"><strong>Ages:</strong> 8-10</p>
        <p class="card-text"><strong>Availability:</strong> 12 spots/week</p>
        <a target="_blank" href="https://docs.google.com/forms/d/13T-RAu0qqPHf2RqYjXiiznAZNjuVXp8TsGo0gwiTEhE/edit" class="btn btn-primary btn-sm primaryjoin-button w-100 mt-2 ">Register</a>
      </div>
    </div>
    <div class="card w-100 card-green mb-4">
      <div class="card-body">
        <h5 class="card-title">Green Ball</h5>
        <p class="card-text"><strong>Duration:</strong> 8 Weeks</p>
        <p class="card-text"><strong>Location:</strong>  Mt Laurel, NJ</p>
        <p class="card-text"><strong>Days:</strong> Tue & Thu</p>
        <p class="card-text"><strong>Time:</strong> 4:30 - 6pm</p>
        <p class="card-text"><strong>Ages:</strong> 9-12</p>
        <p class="card-text"><strong>Availability:</strong> 12 spots/week</p>
        <a target="_blank" href="https://docs.google.com/forms/d/13T-RAu0qqPHf2RqYjXiiznAZNjuVXp8TsGo0gwiTEhE/edit" class="btn btn-primary btn-sm primaryjoin-button w-100 mt-2 ">Register</a>
      </div>
    </div>
    <div class="card w-100 card-blue mb-4">
      <div class="card-body">
        <h5 class="card-title">Tournament Team</h5>
        <p class="card-text"><strong>Duration:</strong> 8 Weeks</p>
        <p class="card-text"><strong>Location:</strong>  Mt Laurel, NJ</p>
        <p class="card-text"><strong>Days:</strong>  Tue & Thu </p>
        <p class="card-text"><strong>Time:</strong> 6 - 8pm</p>
        <p class="card-text"><strong>Ages:</strong> 11-18</p>
        <p class="card-text"><strong>Availability:</strong> 12 spots/week</p>
        <a target="_blank" href="https://docs.google.com/forms/d/13T-RAu0qqPHf2RqYjXiiznAZNjuVXp8TsGo0gwiTEhE/edit" class="btn btn-primary btn-sm primaryjoin-button w-100 mt-2 ">Register</a>
      </div>
    </div>
  </div>
</div>
//     <div class="container mt-5">
//   <h2 class="mb-4">Tennis Clinics</h2>
//   <div class="table-responsive">
//     <table class="table table-bordered">
//       <thead>
//         <tr>
//           <th>Clinic</th>
//           <th>Duration</th>
//           <th>Location</th>
//           <th>Days</th>
//           <th>Time</th>
//           <th>Ages</th>
//           <th>Availability</th>
//           <th>Registration</th>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td>Red Ball</td>
//           <td>8 Weeks</td>
//           <td> Mt Laurel, NJ</td>
//           <td>Mon & Wed</td>
//           <td>4:30 - 5:30</td>
//           <td>4-8</td>
//           <td>12 spots/clinic</td>
//           <td><a href="#" class="btn btn-primary btn-sm">Register</a></td>
//         </tr>
//         <tr>
//           <td>Orange Ball</td>
//           <td>8 Weeks</td>
//           <td>Larchmont Tennis Courts, Mt Laurel, NJ</td>
//           <td>Mon & Wed</td>
//           <td>5:30 - 6:30</td>
//           <td>8-10</td>
//           <td>12 spots/clinic</td>
//           <td><a href="#" class="btn btn-primary btn-sm">Register</a></td>
//         </tr>
//         <tr>
//           <td>Green Ball</td>
//           <td>8 Weeks</td>
//           <td>Larchmont Tennis Courts, Mt Laurel, NJ</td>
//           <td>Tue & Thu</td>
//           <td>4:30 - 6pm</td>
//           <td>9-12</td>
//           <td>12 spots/week</td>
//           <td><a href="#" class="btn btn-primary btn-sm">Register</a></td>
//         </tr>
//         <tr>
//           <td>Tournament Team</td>
//           <td>8 Weeks</td>
//           <td>Larchmont Tennis Courts, Mt Laurel, NJ</td>
//           <td>Varies</td>
//           <td>6 - 8pm</td>
//           <td>11-18</td>
//           <td>12 spots/week</td>
//           <td><a href="#" class="btn btn-primary btn-sm">Register</a></td>
//         </tr>
//       </tbody>
//     </table>
//   </div>
// </div>
  )
}

export default FallComponent