import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import "./popup.css"

const PopupDialogFirst = ({ open, handleClose }) => {
  
  return (
    <Dialog open={open} onClose={handleClose} className="popup-dialog">
      <DialogContent className="dialog-content">
        <div className="close-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        {/* <img src="https://scontent-ord5-2.xx.fbcdn.net/v/t39.30808-6/448861999_350559414741005_2245982961932093599_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=127cfc&_nc_ohc=_HUkdrx-lNEQ7kNvgEYt8t9&_nc_ht=scontent-ord5-2.xx&oh=00_AYAseJzJ78a1U3e-lO6FHL9kKHAcWLN44-MPNwjVjSuZdQ&oe=668770D8" alt="Image From Facebook " className="qr-code-image" /> */}
        <img src="/flyer1.png" alt="Popup" className="qr-code-image" />
      </DialogContent>
    </Dialog>
  );
};

export default PopupDialogFirst;
