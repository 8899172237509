import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

const TennisCampSchedule = () => {
  return (
    <div className="container mt-5 mb-5">
      {/* <h2 className="text-center">UTR SPORTS</h2>
      <h3 className="text-center">Next Gen Tennis Camp</h3>
      <h4 className="text-center">NEXTGEN TENNIS PERFORMANCE</h4>
      <p className="text-center">June 25 - Aug 22</p>
      <p className="text-center">Delran High School</p> */}
      <div className="row bg-success-subtle rounded-4 p-3 w-100">
        {[
          { week: 'Week #1', focus: 'Fundamentals & Footwork' },
          { week: 'Week #2', focus: 'Serve & Return' },
          { week: 'Week #3', focus: 'Net Plays & Volleys' },
          { week: 'Week #4', focus: 'Groundstrokes & Consistency' },
          { week: 'Week #5', focus: 'Match Strategy & Tactics' },
          { week: 'Week #6', focus: 'Physical Conditioning & Mental Toughness' },
          { week: 'Week #7', focus: 'Doubles Play & Teamwork' },
          { week: 'Week #8', focus: 'Tournament Week' }
        ].map((item, index) => (
          <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3  mb-2 mt-2 d-flex justify-content-center align-items-center " >
            <div className="card h-100" style={{width:'200px',height:'300px !important'}}>
              <div className="card-body">
                <h5 className="card-title">{item.week}</h5>
                <p className="card-text">{item.focus}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TennisCampSchedule
