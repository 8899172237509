import "./Testimonial.css";
import React from "react";
import Slider from "react-slick";
import { AiFillStar } from "react-icons/ai";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="testimonialSectionWrapper">
      <div className="testimonialSectionTop">
        <p className="primarySubheading text-capitalize">Testimonial</p>
        <h1 className="primaryHeading">What They Are Saying</h1>
        <p className="primaryText">  
          They provide exceptional coaching and facilities. Joining was the best decision ever. Their community feels like family.
        </p>
      </div>
      <Slider {...settings}>
        <div className="testimonialCard h-100">
          <p>
            My two boys recently visited a local tennis club for the first time, and it turned out to be an unforgettable experience. They had a blast, learning new skills and enjoying quality time with others. However, one of my sons had to board alone. While he managed fine, I’d recommend going with a friend to make it easier to connect with others. Overall, the property was impressive, and there were plenty of activities to keep them engaged during downtime.
          </p>
          <div className="testimonialsStarsContainer">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2>Sarah</h2>
        </div>
        <div className="testimonialCard h-100">
          <p>
            If you want to improve your tennis skills, check out the NextGen tennis camp. The knowledgeable instructors make it worthwhile, and you get to play with players of all levels. Kudos to Zach, Brett, and Gary—they’re the best!
          </p>
          <div className="testimonialsStarsContainer">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2>James</h2>
        </div>
        <div className="testimonialCard h-100">
          <p>
            Zach is fantastic! His tips are spot-on, and his passion for the sport shines through. He’s informed and dedicated to helping players of all levels. I can’t wait for another session with him!
          </p>
          <div className="testimonialsStarsContainer">
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </div>
          <h2>Emily</h2>
        </div>
      </Slider>
    </div>
  );
};

export default Testimonial;
