import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import carouseel2 from '../Assets/Tennisimgages.png';
import carousel4 from '../Assets/grouppic.jpg';
import carousel3 from '../Assets/is open-01.png';
import Carousels1 from '../Assets/tennissBanner.jpg';
import './Carousels.css';
const Carousels = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
    
    <Carousel
    
    className='carousels'

      activeIndex={index}
      onSelect={handleSelect}
      interval={3000}  // Set interval to 5000 milliseconds (5 seconds)
    >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={carousel4}
          alt="Third slide"
          // style={{ height: "70vh", objectFit: "cover" }}
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 "
          src={Carousels1}
          alt="First slide"
          // style={{ height: "70vh", objectFit: "contain",   }}
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={carouseel2}
          alt="Second slide"
          // style={{ height: "70vh", objectFit: "cover" }}
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={carousel3}
          alt="Third slide"
          style={{ height: "50vh", objectFit: "fill",  transform:'translate(0,10px)' }}
        />

      </Carousel.Item>
      
    </Carousel>
    </>
  );
};

export default Carousels;
