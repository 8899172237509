import React from "react";
import "./Contact.css"; // Import your CSS file for styling
import Box from "@mui/material/Box";
import { TextField, Button } from "@mui/material";
import mappic from "../Assets/map.jpg";
import { useForm } from "react-hook-form";

const Contact = ({ location }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data);
    const contactInfo =  data;

    // Constructing the mailto URL
    let subject = encodeURIComponent("Tennis Enquiry"); // Subject of the email
    let body = encodeURIComponent(
      `Name: ${contactInfo.name}\nEmail: ${contactInfo.email}\nPhone: ${contactInfo.phone}\nMessage: ${contactInfo.message}`
    );

    let mailtoUrl = `mailto:${"serve2win9@gmail.com"}?subject=${subject}&body=${body}`;

    // Redirecting to the email client
    window.location.href = mailtoUrl;
  };
  const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2651.00611541631!2d-74.94611712359608!3d40.01339877278971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c14bf429375a7f%3A0xbdfc662e1d394299!2sDelran%20High%20School%20Tennis%20Courts!5e0!3m2!1sen!2sin!4v1720022718263!5m2!1sen!2sin`;
  return (
    <div className="contact-page-wrapper">
      <div className="contact-content">
        <div className="contact-form-container w-100">
          <h2 className="contactheading">Have Questions In Mind?</h2>
          <h2 className="contactheading">Let Us Help You</h2>
          <br />
          {/* <Box
            component="form"
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
            }}
            sx={{
              "& .MuiTextField-root": { m: 1, width: "20ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                required
                id="outlined-required"
                label="First Name"
                // defaultValue="jhon"
              />
              <TextField
                required
                id="outlined-required"
                label="Last Name"
                // defaultValue="doe"
                placeholder="doe"
              />
            </div>
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: "42ch",
                "@media (max-width: 480px)": {
                  // width: '100%', // Adjust width for smaller screens
                  width: "20ch",
                },
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="outlined-required"
              label="Query"
              // defaultValue="problem"
              placeholder="Query"
            />
          </Box> 
          <Button className="submitbutton">Submit</Button> */}
          <br />

          <form 
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex w-100 flex-column justify-content-center align-items-center gap-3 m-auto border border-1 p-3 rounded-2  "
          >
            {/* register your input into the hook by invoking the "register" function */}
            <label> Contact Us</label>
            <TextField
              required
              id="outlined-required"
              label="Name"
              {...register("name")}
              // defaultValue="problem"
              placeholder="name"
              className="w-100"
            />
            <TextField
              required
              id="outlined-required"
              label="Email"
              type="email"
              {...register("email")}
              // defaultValue="problem"
              placeholder="email"
              className="w-100"
            />

            <TextField
              required
              id="outlined-required"
              label="Phone"
              type="number"
              {...register("phone")}
              // defaultValue="problem"
              placeholder="phone"
              className="w-100"
            />

            <TextField
              id="outlined-multiline-static"
              required
              label="Message"
              multiline
              className="w-100"
              rows={4}
              {...register("message")}
              // defaultValue="Default Value"
            />

            {/* errors will return when field validation fails  */}
            {errors.exampleRequired && <span>This field is required</span>}

            <input
              className="submitbutton  w-100 btn btn-outline-primary  "
              type="submit"
            />
          </form>

          <div className="mt-2">
            <h4 className="contactsideheadings">Location</h4>
            <div className="container">
              <div className="center-text">
                50 Hartford Rd, Delran, NJ 08075, USA
              </div>
            </div>

            <h4 className="contactsideheadings">Phone</h4>
            <div className="container">
              <div className="center-text">(856)-581-1072</div>
            </div>
            <h4 className="contactsideheadings">Mail</h4>
            <div className="container">
              <div className="center-text">serve2win9@gmail.com</div>
            </div>
          </div>

          <br />
        </div>
        <div className="map-container">
          {/* Google Maps Embed with a dummy address */}
          <iframe
            title="Map"
            src={mapSrc}
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
