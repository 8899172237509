import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

const UTSA_Events = () => {
  const events = [
    {
      title: 'USTA Junior Circuit: B/G 10U Green Ball Jr Circuit-Friday August 23rd @ Delran HS',
      url: 'https://playtennis.usta.com/Competitions/nextgentennisperformance/Tournaments/Overview/86c7833b-d2d7-4ee7-8d31-74e06130fc42',
    },
    {
      title: 'USTA Junior Circuit: B/G 10U Green Ball Jr Circuit-Friday August 30th @ Delran HS',
      url: 'https://playtennis.usta.com/Competitions/nextgentennisperformance/Tournaments/Overview/3f8216ac-44d3-4c65-a0dd-bb0288615953',
    },
    {
      title: 'Level 7: BOYS 14s Level 7 Saturday Oct 12th @ Delran HS',
      url: 'https://playtennis.usta.com/Competitions/nextgentennisperformance/Tournaments/Overview/166bfdee-38c5-4db6-891e-cd21c24124d1',
    },
  ];

  return (
    <div className='mb-3'>
      <h1 className='mb-3'>
        USTA Events <img className='rounded-4' width={250} src="utsa.png" alt="USTA" />
      </h1>
      <hr />
      <Grid container spacing={3}>
        {events.map((event, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent className="position-relative overflow-hidden">

                <div
                 className='   mb-3 rounded-2 d-flex justify-content-center align-items-senter flex-wrap opacity-50' 
                 style={{width:"100%",height:"200px", background:"black",alignContent:"center"}}>
                <img className=' ' width={250}  style={{height:""}} src="utsa.png" alt="USTA" />
                 
                </div> 
                <Typography variant="h6" gutterBottom>
                  {event.title}
                </Typography>
                <Button
                className='mt-3'
                style={{background:"black",color:"white"}}
                  variant="contained"
                //   color="primary"
                  href={event.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <hr />
    </div>
  );
};

export default UTSA_Events;