import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import ballpic from "../Assets/ball_pic-removebg-preview.png";
import smallImage from "../Assets/price.png";
import "./Events.css";

function BasicExample() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      offset: 200, // Offset (in px) from the original trigger point
      easing: "ease-in-out", // Easing function
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <>
      <h3
        style={{ fontWeight: "bolder", textAlign: "center" }}
        className="primary-subheading"
      >
        Camps
      </h3>

      {/* <div className="detailscard align-image">
        <div className="details-content ">
          <div className="EventImgContainer col-3">
            <img className="EventImg" src={ballpic} alt="no image"></img>
          </div>
          <div className="details col-4 ">
            <h2>About NextGen Tennis Performance</h2>
            <p>
              <strong>PM SESSION:</strong> TUE/WED/THU
            </p>
            <p>
              <strong>Competitive & High School level Training:</strong> 5pm -
              8pm
            </p>
            <p>
              <strong>Intermediate & Advanced Players:</strong> Skill based
              grouping
            </p>
            <p>
              <strong>10 & Under Beginner Group:</strong> 5pm - 7pm
            </p>
            <p>
              <strong>10 & Under Competitive Group:</strong> 5pm - 8pm
            </p>
            <p>
              <strong>
                Flexible 1-1 Private Sessions / Semi-Private / Group Sessions
              </strong>
            </p>
          </div>
          <div className="details-image col-5 ">
            <img src={smallImage} alt="Small" />
          </div>
        </div>
      </div> */}



<div className="detailscard align-image">
        <div className="details-content w-100 d-lg-flex  gap-3">
          <div className="EventImgContainer  ">
            <img className="EventImg w-100 h-100" src={ballpic} alt="no image"></img>
          </div>
          <div className="details   ">
            <h2>About NextGen Tennis Performance</h2>
            <p>
              <strong>PM SESSION:</strong> TUE/WED/THU
            </p>
            <p>
              <strong>Competitive & High School level Training:</strong> 5pm -
              8pm
            </p>
            <p>
              <strong>Intermediate & Advanced Players:</strong> Skill based
              grouping
            </p>
            <p>
              <strong>10 & Under Beginner Group:</strong> 5pm - 7pm
            </p>
            <p>
              <strong>10 & Under Competitive Group:</strong> 5pm - 8pm
            </p>
            <p>
              <strong>
                Flexible 1-1 Private Sessions / Semi-Private / Group Sessions
              </strong>
            </p>
          </div>
          <div className="details-image w-100 ">
            <img className="w-100" src={smallImage} alt="Small"  />
          </div>
        </div>
      </div>


      <div className="campheadpart">
        <div>
          <li className="camplists">UTR matchplays conducted every week</li>
          <li className="camplists">
            All classes including fitness and injury prevention
          </li>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-sm-1 p-2 row-cols-md-2">
        <div className="col m-auto">
          <div className="detailscard">
            <div className="details-content">
              <div className="details">
                <h4>10 & Under - Development & Competitive Camp:</h4>
                <div className="expand-content">
                  <ul>
                    <li>
                      Orange / Green Ball focused Groups for Ages 10 Under
                      Beginner/Intermediate and Competitive skill level.
                    </li>
                    {/* <li>Click on Divisions to see the pricing options.</li> */}
                    <li>Free Trials and Evaluation Sessions offered.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col m-auto">
          <div className="detailscard">
            <div className="details-content">
              <div className="details">
                <h4>Development & Competitive Camp:</h4>
                <div className="expand-content">
                  <ul>
                    <li>
                      Yellow Ball smaller focused Groups for Ages 11-17 years.
                    </li>
                    <li>
                      Beginner/Intermediate skill level, High School Varsity &
                      Jr Varsity prep.
                    </li>
                    <li>Free Trials and Evaluation Sessions offered.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col m-auto">
          <div className="detailscard">
            <div className="details-content">
              <div className="details">
                <h4>Camp Details & Structure:</h4>
                <div className="expand-content">
                  <ul>
                    <li>
                      Highly engaging coaches with ATP professional & college
                      experience.
                    </li>
                    <li>
                      Skill based grouping, interactive games and fun drills.
                    </li>
                    <li>
                      Techniques and strategies for tournament preparation
                      personalized attention and customized feedback UTR
                      tournament events hosted through summer.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col m-auto">
          <div className="detailscard">
            <div className="details-content">
              <div className="details">
                <h4>Players will be grouped by UTR:</h4>
                <div className="expand-content">
                  <ul>
                    <li>
                      Players train on Monday Through Thursday ( PM sessions )
                    </li>
                    <li>Matchplays on Friday / Sat (Format TBA every week)</li>
                    <li>Match Plays every week will count towards UTR</li>
                    <li>Junior Circuits and Tournaments on Weekend.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col m-auto">
          <div className="detailscard">
            <div className="details-content">
              <div className="details">
                <h4>Weather, Refund & Cancellation Policy:</h4>
                <div className="expand-content">
                  <p>
                    If you need to cancel and receive a refund for your camp
                    registrations, you have until July 1st,2024 to notify
                    NextGen Tennis for a full camp refund. NextGen Tennis does
                    not allow any makeup, refund or credit for any missed camp
                    sessions where you decide not to participate for any reason
                    other than NextGen Tennis responsibility or weather
                    cancellation.
                  </p>{" "}
                  <p>
                    During the camp sessions, your child participates in, If it
                    rains prior to camp hours and the weather is projected to be
                    a complete wash out the camp will be canceled as we cannot
                    guarantee a safe and fun environment
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col m-auto">
          <div className="detailscard">
            <div className="details-content">
              <div className="details">
                <h4>Questions :</h4>
                <div className="expand-content">
                  <p>
                    Please fill in this Pre-Registration form and we will get
                    back to you.
                    <a
                      href="https://docs.google.com/forms/d/1M0a3OO4AIj9PKR40KUo0RkrP2SyeeI867tzfH8KhxGg/edit"
                      target="_blank"
                    >
                      Fill Form
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}

export default BasicExample;
