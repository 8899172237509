import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Events.css";
import boyadultsimage from "../Assets/aboveadults.jpg";
import boychildimage from "../Assets/boychilds.jpg";
import smallImage from "../Assets/price.png";
import girlchildimages from "../Assets/girlchilds.jpg";
import girlchildimages1 from "../Assets/kidstennis1.jpg";
import adultsimages from "../Assets/adultssp.jpg";
import cogamers from "../Assets/33251.jpg";
import boy from "../Assets/boy.jpg";
import boy2 from "../Assets/boy2.avif";
import boy3 from "../Assets/boy3.avif";
import ballpic from "../Assets/ball_pic-removebg-preview.png";
import { MdEmojiEvents } from "react-icons/md";
const UpComingEvents = () => {
  return (
    
    <div className="w-100 mb-3">
    <p className="primary-subheading">Upcoming Events</p>
      <div className="cards">
      <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={girlchildimages} />
          <Card.Body className="d-flex flex-column justify-content-between" >
            <Card.Title className="cardstitles">
            NEXTGEN Verified UTR Yellow Matchplay
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  Aug 23<sup>rd</sup> | Fri 4pm EST
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button"
                href="https://app.utrsports.net/events/256061"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card>
        <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={cogamers} />
          <Card.Body className="d-flex flex-column justify-content-between" >
            <Card.Title className="cardstitles">
            NEXTGEN Verified UTR Yellow Matchplay 
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  Aug 29<sup>th</sup> | Fri 4pm EST
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button"
                href=" https://app.utrsports.net/events/256060?t=1"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card>
        <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={adultsimages} />
          <Card.Body className="d-flex flex-column justify-content-between" >
            <Card.Title className="cardstitles">
            NEXTGEN Verified UTR Yellow & Greenball  Matchplay
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  Sep 7<sup>th</sup> | Sat 9:30am EST
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button"
                href=" https://app.utrsports.net/events/258200?t=1"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card>








        {/* <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={adultsimages} />
          <Card.Body className="d-flex flex-column justify-content-between" >
            <Card.Title className="cardstitles">
              NEXTGEN Verified UTR Matchplay
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  JUL 12<sup>th</sup> | 4:00 - 8:00 PM
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button"
                href="https://app.utrsports.net/events/252198"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card> */}
        {/* <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={cogamers} />
          <Card.Body className="d-flex flex-column justify-content-between">
            <Card.Title className="cardstitles">
              NEXTGEN Verified UTR Matchplay
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  JUL 19<sup>th</sup> | 4:00 - 8:00 PM
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button "
                href="https://app.utrsports.net/events/252198"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card> */}
        {/* <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={girlchildimages} />
          <Card.Body className="d-flex flex-column justify-content-between" >
            <Card.Title className="cardstitles">
              NEXTGEN Verified UTR Matchplay
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  JUL 26<sup>th</sup> | 4:00 - 8:00 PM
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button"
                href="https://app.utrsports.net/events/252198"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card> */}
        {/* <Card
          className="individualcards"
          style={{ width: "18rem" }}
          data-aos="fade-up"
        >
          <Card.Img variant="top" src={girlchildimages1} />
          <Card.Body className="d-flex flex-column justify-content-between" >
            <Card.Title className="cardstitles">
              NEXTGEN Verified UTR Matchplay
            </Card.Title>
            <Card.Text>
              <center>
                <h6>
                  AUG 2<sup>nd</sup> | 4:00 - 8:00 PM
                </h6>
                <h6>DELRAN HIGH SCHOOL</h6>
              </center>
            </Card.Text>
            <center>
              <Button
                className="primary-button"
                href="https://app.utrsports.net/events/252198"
                target="_blank"
              >
                Registration Open
              </Button>
            </center>
          </Card.Body>
        </Card> */}
      </div>
    </div>
  )
}

export default UpComingEvents